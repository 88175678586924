.customChatBot1 .chat-window {
  width: 45%;
  height: 83vh;
  position: fixed;
  right: 25px;
  bottom: 95px;
  box-sizing: border-box;
  box-shadow: 2px -2px 8px 3px #e9ecef;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  z-index: 999999999999;
  background: #ffffff;
}

@media only screen and (max-width: 1024px) {
  .customChatBot1 .chat-window {
    width: 65%;
    height: 80%;
    right: 0px;
    bottom: 90px;
    border-radius: 0px;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }
}

@media only screen and (max-width: 768px) {
  .customChatBot1 .chat-window {
    width: 85%;
    height: 80%;
    right: 0px;
    bottom: 90px;
    border-radius: 0px;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }
}

@media only screen and (max-width: 600px) {
  .customChatBot1 .chat-window {
    width: 100%;
    height: 100%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }
}

@media screen and (max-width: 1000px) and (max-height: 500px) {
  .customChatBot1 .chat-window {
    width: 100%;
    height: 100%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px !important;
    /* border-top-left-radius: 0px;
    border-top-right-radius: 0px; */
  }
}
