.customChatBot1 .canvas {
  display: block;
  justify-content: center;
  align-items: center;
  height: 20px;
}
.customChatBot1 .ball {
  display: inline-block;
  width: 8px;
  height: 9px;
  border-radius: 11px;
  margin: 0 2px;
  animation: 2s bounce ease infinite;

  animation: 2s bounce ease infinite;
}
.customChatBot1 .blue {
  background-color: #0053df;
}
.customChatBot1 .red {
  background-color: #952b2b;
  animation-delay: 0.25s;
}
.customChatBot1 .yellow {
  background-color: #ff7510;
  animation-delay: 0.5s;
}
.customChatBot1 .green {
  background-color: #34a952;
  animation-delay: 0.75s;
}
@keyframes bounce {
  50% {
    transform: translateY(10px);
  }
}
