.customChatBot1 .message_parent {
  width: 100%;
  position: relative;
  margin-top: 2px;
  display: inline-block;
  margin-left: 5px;
}
.customChatBot1 .messageImg .message_bot_logo {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}
.customChatBot1 .message_bot_logo {
  width: 27px;
  height: 27px;
  position: sticky;
  background: #ffffff;
  border-radius: 50%;
  padding: 2px;
  border: #ddd 1px solid;
}

.customChatBot1 .bot-message {
  background-color: #2ea8ea;
  /* background-image: linear-gradient(to right, #1ca7ec 0%, #7ad5f5 100%); */
  /* background-image: linear-gradient(90deg,#1500ff,#932dfb); */
  padding: 2px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  width: fit-content;
  clear: both;
  border-color: #eaeaea;
  border-width: 0.1rem;
  border-style: solid;
  margin-right: 5px;
  display: inline-block;
  margin: 0px !important;

  /* background-color: #eee;
  padding: 2px;
  border-top-right-radius: 13px;
  border-bottom-left-radius: 13px;
  border-radius: 14px;
  width: -moz-fit-content;
  width: fit-content;
  clear: both;
  border-color: #d0cfcf;
  border-width: 0.1rem;
  border-style: solid;
  margin-right: 5px;
  display: inline-block;
  margin: 0px !important; */
}
/* .customChatBot1 .Botmessages {
  margin-left: 2rem;
} */
.customChatBot1 .messageImgICONUser {
  height: 24px;
  padding-right: 10px;
  vertical-align: top;
  /* margin-right: 14px; */
  /* display: inline-block !important; */
  padding-bottom: 29px;
}
.customChatBot1 .messageImg {
  padding-left: 9px;
  vertical-align: top;
  /* margin-right: 14px; */
  /* display: inline-block !important; */
  padding-bottom: 3px;
  padding-top: 4px;
}
.customChatBot1 .messageTextBot {
  display: inline-block !important;
  margin-left: 8px !important;
  margin-right: 95px !important;
  padding-bottom: 2px;
  position: relative;
}
.customChatBot1 .messageTextBotWithIcon:before {
  content: "";
  position: absolute;
  top: -7px;
  left: 1px;
  border-color: transparent transparent transparent #1ca7ec;

  border-style: solid;
  border-width: 15px 20px 0px 20px;
  height: 0px;
  width: 0px;
}
.customChatBot1 .messageTextWithLogoBot {
  padding-bottom: 2px;
}
.customChatBot1 .messageTextUser {
  float: right;
  padding-right: 7px;
  padding-bottom: 0px;
  position: relative;
  padding-left: 125px;
}
.customChatBot1 .messageImgICONUser .userPersonLogo {
  display: block;
  float: right;
  width: 28px;
  height: 28px;
  position: sticky;
  background: #ffffff;
  color: #1c4fc3;
  border-radius: 50%;
  padding: 2px 0px 0px 0px;
  font-size: 28px;

  border: #ddd 1px solid;
}
.customChatBot1 .messageTextUser:before {
  content: "";
  position: absolute;
  top: -5px;
  right: 7px;
  border-color: transparent #1c4fc3 transparent transparent;
  border-style: solid;
  border-width: 6px 13px 0px 8px;
  height: 0px;
  width: 0px;
}
.customChatBot1 .Usermessages {
  margin-right: 2rem;
  margin-top: 0px;
}
.customChatBot1 .user-message {
  background-color: #ffffff;
  /* background-image :linear-gradient(to right, #1500ff , #7c00f9); */
  /* background-image: linear-gradient(to right, #c6c3c3 , #e6e6e6); */
  padding: 3px;
  border-top-left-radius: 11px;
  border-bottom-right-radius: 11px;
  width: -moz-fit-content;
  width: fit-content;
  clear: both;
  border-bottom-left-radius: 11px;
  border: 1px solid #1c4fc3;
}
.customChatBot1 .white-text {
  color: #1c4fc3;
  padding: 3px 5px 3px 5px;
  font-size: 14px;
}
.customChatBot1 .loaderMessage {
  width: 80px;
}
.customChatBot1 .black-text {
  padding: 2px 5px 3px 3px;
  font-size: 14px;
  color: #ffffff;
}

.customChatBot1 .timmer {
  text-align: end;
  font-size: 12px;
  padding-right: 5px;
}

.customChatBot1 .loaderMessageBox {
  clear: both;
  /* display: flex; */
}

.customChatBot1 .loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 0.5em;
  height: 0.6em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.6s infinite ease-in-out;
  animation: load7 1.6s infinite ease-in-out;
}

.customChatBot1 .loader {
  color: #1c4fc3;
  top: -2.8em;
  left: -0.3em;
  font-size: 10px;
  /* margin: 80px auto; */
  float: left;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.customChatBot1 .loader:before,
.customChatBot1 .loader:after {
  content: "";
  position: absolute;
  width: 20px;
  top: 0;
  top: 0;
}
.customChatBot1 .loader:before {
  left: 1.2em;
  -webkit-animation-delay: -0.32s;
  width: 6px;
  top: 0;
  animation-delay: -0.2s;
}
.customChatBot1 .loader:after {
  left: 2.3em;
  width: 6px;
  top: 0;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
