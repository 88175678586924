.quickButtonDiv {
  display: inline-block;
  padding-left: 0px !important;
  padding-right: 10px !important;
  /* min-width: fit-content; */
  /* max-width: 42% !important; */
}
.quickButton {
  padding: 5px 10px !important;
  margin: 2px;
  border-radius: 2.25rem !important;
  background-color: #1c4fc3 !important;
  color: #ffffff !important;
  border: 1px solid #1c4fc3 !important;

  /* background-image: linear-gradient(90deg,#1500ff,#7c00f9); */
}
.quickButtonDiv .btn-primary:hover {
  color: #000000 !important;
  /* background-image: linear-gradient(90deg,#1500ff,#7c00f9); */
  background-image: linear-gradient(90deg, #ffffff, #ffffff);
  border: 1px solid #1c4fc3 !important;
}
/* .quickButton :hover{
  border-radius: 2.25rem  !important;
  border: 1px solid #e6e6e6 !important;
  background: #fff  !important;
  color: black !important;
} */
.button_text {
  font-size: 14px;
}

@media screen and (max-width: 460px) {
  .quickButtonDiv {
    min-width: fit-content;
    /* max-width: 42% !important; */
  }
}
