.customChatBot1 .header_promo {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  transition: height 0.4s linear;

  /*pointer-events: none;*/
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* height: 26vh; */
  /* height: 14vh; */
  height: 20vh;
  position: sticky;
  background: #2da7ea;

  /* background-image: linear-gradient(to right, #1ca7ec 0%, #7ad5f5 100%); */
}
.customChatBot1 .header_promo_img {
  /* width: 10vh;
  height: 10vh;
  position: -webkit-sticky;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  padding: 2px; */

  /* width: 15vh; */
  width: auto;
  /* height: 10vh; */
  height: auto;
  /* position: -webkit-sticky; */
  /* background: rgb(255, 255, 255); */
  border-radius: 10%;
  /* padding/: 9px; */
  /* height: 42px; */
  width: 50px;
  object-fit: contain;
  padding: 4px;
}

.customChatBot1 .header_title_promo {
  text-align: center;
  color: #000;
}

.customChatBot1 .header_container_inner_promo {
  align-items: center;
  text-align: center;
  /* display: flex; */
  padding-top: 10px;
  width: 100%;
}

.customChatBot1 .h5Style_promo {
  margin: 1px;
}
.customChatBot1 .h6Style_promo {
  margin: 1px 1px 1px 5px;
  font-size: 14px;
}

.customChatBot1 .drop_down_icon {
  float: left;
  color: #ffffff;
  position: absolute;
  padding: 8px;
  top: 5px;
  left: 5px;
}

.customChatBot1 .trancription_icon {
  float: left;
  color: #ffffff;
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 5px;
}
.customChatBot1 #trancription_icon:hover {
  cursor: grab;
  background-color: #e6e6e6cc;
  color: #000;
  border: blanchedalmond 1px;
  border-radius: 11px;
  padding-bottom: 1px;
}
.customChatBot1 #trancription_icon:hover #tooltip {
  visibility: visible;
}
.customChatBot1 #drop_down_icon:hover {
  cursor: grab;
  background-color: #e6e6e6cc;
  color: #000;
  border: blanchedalmond 1px;
  border-radius: 11px;
  padding-bottom: 1px;
}

.customChatBot1 .header_main {
  display: flex;
  flex-direction: column;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* height: 100px; */
  /* position: sticky; */
  background: #2da7ea;
  /* background: #1c4fc3; */
  /* background-image: linear-gradient(to right, #1ca7ec 0%, #7ad5f5 100%); */
  transition: height 0.5s linear;
}

.customChatBot1 .header_main .header_container_inner_promo {
  /* align-items: unset;
  text-align: unset;
  padding-top: 0px;
  display: flex;
  justify-content: center; */

  /* align-items: flex-start; */
  text-align: unset;
  width: 100%;
  display: flex;

  padding-top: 0px;
  display: flex;
  /* justify-content: left; */
  margin-left: 45px;
}
.customChatBot1 .listViewMenu {
  position: absolute;
  right: 10px;
  text-align: left;
  z-index: 999;
  top: 50px;
  border: 1px solid #ddd;
  /* border-radius: 10px; */
}

.customChatBot1 .header_main .header_promo_img {
  width: 50px;
  /* margin-bottom: 10px; */
  /* height: 60px; */
  object-fit: contain;
  /* height: 40px; */
  position: -webkit-sticky;
  /* background: rgb(255, 255, 255); */
  border-radius: 10%;
  /* padding: 4px; */
}
.customChatBot1 .header_main .header_img_div {
  margin: 10px 0;
  display: flex;
  /* height: 300px; */
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  /* align-content: center; */
  /* padding-left: 90px; */
}

.customChatBot1 .header_main .header_img_div img {
  margin: 0 auto;
}

.customChatBot1 .header_main .header_img_div p {
  color: #000;
  display: none;
}

/* .customChatBot1 .header_main .header_title_promo {
  text-align: left;
  color: #f9f9f9;
} */
.customChatBot1 .header_main .h5Style_promo {
  margin: 5px 5px 1px 5px;
  font-size: 1rem;
}
.customChatBot1 .header_main .h6Style_promo {
  font-size: 0.8rem;
}

@media only screen and (max-width: 768px) {
  .customChatBot1 .header_promo {
    height: 15vh;
  }
}

@media only screen and (max-width: 600px) {
  .customChatBot1 .header_main {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .customChatBot1 .header_promo {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

@media screen and (max-width: 1000px) and (max-height: 500px) {
  .customChatBot1 .header_main {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .customChatBot1 .header_promo {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

.customChatBot1 #tooltip {
  position: absolute;
  left: 0px;
  top: 0px;
  visibility: hidden;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 13px;

  display: inline-block;
}

.customChatBot1 #tooltip.right {
  margin-left: 5px;
}

.customChatBot1 #tooltip.left {
  margin-left: -5px;
}

.customChatBot1 #tooltip.top {
  margin-top: -5px;
}

.customChatBot1 #tooltip.bottom {
  margin-top: 5px;
}

.customChatBot1 #tooltip .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.customChatBot1 #tooltip.right .tooltip-arrow {
  top: 50%;
  left: auto;
  margin-left: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.customChatBot1 #tooltip.top .tooltip-arrow {
  top: auto;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.customChatBot1 #tooltip.left .tooltip-arrow {
  top: 50%;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
  right: -5px;
  left: auto;
}

.customChatBot1 #tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.customChatBot1 #tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

.customChatBot1 #tooltip .tooltip-label {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.customChatBot1 .trancription_icon_Css {
  float: left;
  color: #ffffff;
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 5px;
  cursor: grab;
  background-color: #338def;
  border: blanchedalmond 1px;
  border-radius: 11px;
  padding-bottom: 1px;
}

@media screen and (max-width: 691px) {
  .customChatBot1 .header_main {
    height: 30vh;
  }
  .customChatBot1 .header_promo {
    height: 20vh;
  }
}

@media screen and (max-width: 560px) {
  .customChatBot1 .header_main {
    height: 10vh;
    display: flex;
    flex-direction: column;
  }

  .customChatBot1 .header_promo {
    height: 20vh;
  }
}
