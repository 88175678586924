.customChatBot1 .footerTextInput {
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.customChatBot1 .row {
  margin: 0px !important;
  height: 40px;
}
.customChatBot1 .sendMessage {
  padding-right: 5px;
  padding-left: 5px;
}
.customChatBot1 .sendInoutBar {
  background: transparent;
  outline: none;
  border: 0px;
  width: 100%;
  height: 100%;
  padding-left: 10px;
}
.customChatBot1 .sendMessageButton {
  display: inline-block;
  padding: 0px !important;
  cursor: pointer;
  margin: 0px 0px 9px 15px;

  vertical-align: middle;
}
/* .customChatBot1 .sendMessageButton:hover{
  border: 1px solid #1c4fc3;
  border-radius: 100%;
  background-image: linear-gradient(to right, #8b00ff , #6000c1);
  color: #fff;
} */
.customChatBot1 .footerTextInput .col-md-2 {
  max-width: 9% !important;
}
.customChatBot1 .footerTextInput .col-md-2:hover {
  /* border: 1px solid #1c4fc3; */
  border-radius: 100%;
  background-color: #1c4fc3;
  color: #ffffff;
}
.customChatBot1 .sendMessageButton .material-icons {
  margin-left: 8px;
  margin-top: 4px;
}
.customChatBot1 .poweredByFooter {
  font-size: 13px;
  text-align: right;
  margin-right: 10px;
  display: inline-block;
  float: right;
  color: #ffc613;
  display: none;
}
.customChatBot1 .footerParent {
  padding-top: 2px;
  background: #fff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #fff;
  border-top: 2px solid #e6e6e6;
  background-color: #fff;
  margin-top: 1px;
}
.footerHelpButton {
  display: inline-block;
  color: "#1C4FC3";
  font-weight: 600;
  background: #1c4fc3;
  /* background: #e9ecef; */
  border: 2px solid #eee;
  border-radius: 4px;
  padding: 2px;
  color: #fff;
  padding-right: 20px;
  margin-bottom: 2px;
  margin-left: 10px;
  padding-left: 20px;
  /* box-shadow: 0px 0px 0px 1px rgba(100, 100, 100, 0.3); */
}

.footerHelpButton:hover {
  background: #e9ecef;
  color: #000;
  cursor: pointer;
  border: 2px solid #555;
}
.redmic {
  display: inline-block;
  /* margin-top: 5px; */
  cursor: pointer;
  vertical-align: middle;

  color: #ff0000;
}
.micButton {
  display: inline-block;
  margin-bottom: 5px;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 5px;
  padding: 0% !important;
}

.micButton:hover {
  border: 1px solid;
  border-radius: 100%;
  background-color: #1c4fc3;
  color: #fff;
}

.customChatBot1 .footerTextInput .col-md-3 {
  max-width: 9% !important;
  /* border: 1px solid ;
    border-radius: 100%; */
}

.customChatBot1 .redmic .canvas {
  display: block;
  justify-content: center;
  align-items: center;
  height: 20px;
}
.customChatBot1 .redmic .ball {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 11px;
  margin: 0 2px;
  animation: 2s bounce ease infinite;
  animation: 2s bounce ease infinite;
}
.customChatBot1 .redmic .blue {
  background-color: #0053df;
}
.customChatBot1 .redmic .red {
  background-color: #952b2b;
  animation-delay: 0.25s;
}
.customChatBot1 .redmic .yellow {
  background-color: #ff7510;
  animation-delay: 0.5s;
}
.customChatBot1 .redmic .green {
  background-color: #34a952;
  animation-delay: 0.75s;
}

@keyframes bounce {
  50% {
    transform: translateY(10px);
  }
}

#container_mic {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

#container_mic .circle {
  border-radius: 50%;
  background-color: #1c4fc3;
  width: 22px;
  height: 22px;

  position: absolute;
  opacity: 0;
  animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
  top: 3px;
}

@keyframes scaleIn {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}
