.customChatBot1 .chatbot_icon_div_onHide {
  bottom: 27px;
  right: 22px;
  position: fixed;
  vertical-align: top;
  margin-right: 5px;
  z-index: 999;
  display: inline-block !important;
}
.customChatBot1 .chatbot_icon_image_onHide {
  width: 63px;
  height: 60px;
  animation: shadow 1.5s ease-in-out infinite alternate-reverse;

  position: -webkit-sticky;
  position: sticky;
  background: #ffffff;
  /* padding: 10px; */
  border-radius: 50%;
  /* padding: 6px; */
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.7);
  z-index: 999;
  border: #1ca7ec 2px solid;
}

.customChatBot1 #popup_chat {
  position: fixed;
  background: #fff;
  max-width: 250px;
  border-radius: 6px;
  box-shadow: 0 0 8px 1px #b1b0b0;
  right: 41px;
  bottom: 104px;
}
.customChatBot1 #popup_chat p {
  margin: 0;
  padding: 10px;
  font-size: 12px;
  font-weight: 510;
}
.customChatBot1 #popup_chat:after {
  right: 6px;
  border: 10px solid transparent;
  border-top-color: #bdbdbd;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: auto;
  bottom: auto;
}
.customChatBot1 #onHideIcon:hover {
  cursor: pointer;
}

@keyframes shadow {
  0% {
    transform: scale(1);
    box-shadow: 0px 0px 8px 5px #0d4d99;
  }
  50% {
    box-shadow: 0px 0px 8px 10px rgb(197, 225, 249, 0.5);
  }
  100% {
    transform: scale(1);
    box-shadow: 0px 0px 8px 15px rgb(42, 126, 189, 0);
  }
}
