.landing {
  height: 100vh;
  padding: 30px 60px;
  background-image: url(../../assests/images/chatbot/Mask_Group.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.logoImage {
  width: 50px;
  object-fit: contain;
  margin-right: 20px;
}

.landingTop {
  width: 48%;
  display: flex;
  align-items: center !important;
  /* margin-bottom: 20px; */
}

.landing sub {
  margin-left: 70px;
  font-weight: 600;
}

.landingTop h4 {
  margin-bottom: 0;
}

.landingMain {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
  height: auto;
}

.landingDescription {
  width: 80%;
  margin: 20px auto;
}

.landingDescriptionWrapper {
  margin-top: 120px !important;
}

.landingMainLeft,
.landingMainRight {
  /* background-color: rgb(107, 200, 228); */
  display: flex;
  flex-direction: column;
}
.landingMainLeft {
  width: 58%;
}
.landingMainRight {
  width: 42%;
}

.landingDescription__heading,
.landingWorking__heading {
  font-size: 30px;
  font-weight: 550;
  color: #3fa7ed;
  margin-top: 60px;
  margin-bottom: 5px;
  text-align: center;
}
.landingDescription__heading {
  margin-top: 20px;
}
.landingWorking__heading {
  margin-top: 20px;
}
.landingWorking {
  display: flex;
  flex-direction: column;
}
.landingWorking img {
  margin: 0 auto;
  width: 80%;
}

.landingMainLeft h3 {
  font-size: 20px;
}

.landingMainLeft__text {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 10px;
  font-weight: bold;
}

.powered-by {
  text-align: center;
}

.powered-by span {
  font-size: 14px;
}
.powered-by strong {
  font-size: 16px;
}

.askMe {
  padding: 10px 50px;
  width: fit-content;
  border: none;
  background-color: #3048bc;
  border: 2px solid #3048bc;
  color: #fff;
  border-radius: 20px;
  font-size: 16px;
  outline: none;
  /* margin: 0 auto; */
  margin-left: auto;
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: all 0.2s ease-out;
  box-shadow: 0 3px 5px 1px #94afbb;
}

.askMe:hover {
  color: #3048bc;
  background-color: #fff;
  border: 2px solid #3048bc;
  box-sizing: border-box;
}

.askMe:focus {
  outline: none;
}

.landingMainRight__image {
  width: 550px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-left: auto !important;
}

.landingDescription__text {
  text-align: center;
  line-height: 1.8;
  font-weight: bold;
}

.features {
  display: flex;
  width: 90%;
  justify-content: space-between;
  padding: 10px;
  margin: 0 auto;
  /* margin-bottom: 30px; */
  /* border: 2px solid red; */
}

.featureOne {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 22%;
  border: 2px solid #3ca8f7;
  padding: 30px 30px;
  border-radius: 20px;
  transition: all 0.3s;
}

.featureOne:hover {
  /* background-color: #c5e1f9; */

  box-shadow: 0px 2px 5px 5px lightgray;
}

.featureOne img {
  width: 50px;
  margin: 10px auto;
}
.horizontal-rule {
  border-bottom: 2px solid blue;
}

.featureOne p {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.featureWrapper {
  display: flex;
  align-items: center;
  width: 50%;
}
.upperFeature {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* flex-direction: column; */
}

.newFeature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #b3e5fb;
  border-radius: 20px;
  padding: 20px 30px;
  margin-right: 10px;
  color: #fff;
}

.newFeatureText {
  margin-bottom: 0;
  white-space: nowrap;
}

.landingMainLeft img {
  width: 100%;
}

hr {
  background-color: #fff;
  padding: 0;
  margin: 0;
}

hr.hr-1 {
  border: 0;
  height: 3px;
  background-image: linear-gradient(
    to right,
    rgba(13, 77, 153, 0.05),
    rgba(13, 77, 153, 0.8),
    rgba(13, 77, 153, 0.05)
  );
}
.hr-landingTop {
  margin: 1em;
  width: 35%;
}
.hr-landingWorking {
  width: 50%;
  margin: 0 auto;
}

.showWorkOnMobile {
  display: none;
}

/* @media screen and (min-width: 1800px) {
	.landing {
		background-size: 880px;
		background-position: top right;
	}
} */

@media screen and (max-width: 1100px) {
  .landing {
    background-position: top right;
    background-size: 620px;
    padding: 20px 30px;
  }
  .landingMainLeft {
    width: 75%;
  }
  .features {
    width: 100%;
  }
  .features p {
    font-size: 14px;
  }
  .landingDescriptionWrapper {
    margin-top: 20px !important;
  }
  /* .landingTop {
		width: 48%;
	} */
}

@media screen and (max-width: 768px) {
  .landing {
    background-position: top right;
    background-size: 520px;
    padding: 20px 30px;
  }

  .landingMain {
    width: 100%;
  }
  .landingMainLeft {
    width: 100%;
  }
  .landingMainLeft img {
    width: 90%;
    margin-top: 25px;
  }
  .landingMainRight__image {
    width: 380px;
  }
  .landingDescription__heading {
    font-size: 28px;
  }
  .landingMainLeft h3 {
    font-size: 20px;
  }
  .landingMainLeft__text {
    line-height: 2;
  }

  .newFeatureText {
    margin-bottom: 0;
    white-space: pre-wrap;
  }
  .features {
    width: 100%;
  }
  .featureOne {
    padding: 20px;
  }
  .landingWorking__heading {
    font-size: 28px;
  }
  .featureOne p {
    font-size: 12px;
  }
}

@media screen and (max-width: 460px) {
  .landing {
    padding: 10px;
    background-position: top right;
    background-size: 220px;
  }
  .logoImage {
    margin-right: 10px;
  }
  .landingTop {
    display: flex;
    /* flex-direction: column; */
    width: 60%;
    justify-content: flex-start;
  }

  .landingTop h4 {
    font-size: 11px !important;
  }
  .landing sub {
    margin-left: 20px;
  }
  .landingMain {
    position: relative;
    /* margin-top: 100px; */
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
  .landingMainLeft img {
    width: 100%;
  }
  .landingDescription__heading {
    font-size: 18px;
  }
  .landingMainLeft h3 {
    font-size: 14px;
  }
  .landingMainLeft__text {
    font-size: 12px;
    margin-top: 10px;
  }
  .askMe {
    padding: 10px 40px;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-right: auto;
  }
  .landingMainRight__image {
    display: none;
  }
  .upperFeature {
    flex-direction: column;
  }
  .featureWrapper {
    margin-bottom: 10px;
    width: auto;
  }
  .forMobileBottom {
    padding: 0 10px 10px 10px;
  }
  .forMobileTop {
    /* padding: 10px 10px 10px 0px; */
    margin-bottom: 0px;
  }
  .forRemovingMobileTopFeatureMargin {
    margin-bottom: 0;
  }
  .features {
    display: flex;
    flex-direction: column;
  }
  .featureOne {
    width: 80%;
    margin: 20px auto;
  }
  .landingWorking__heading {
    margin-top: 0;
    font-size: 18px;
  }
  .landingMainLeft img {
    width: 80%;
  }
  .hr-landingTop {
    width: 50%;
  }
  .showWorkOnMobile {
    display: block;
    margin-bottom: 20px !important;
    box-shadow: 0 0 5px 5px lightgray;
  }
  .showWorkOnDesktop {
    display: none;
  }
  .firstHowItWorks {
    margin-top: 20px !important;
  }
  .featureOne img {
    width: 30px;
  }
}

@media screen and (max-width: 320px) {
  .landing {
    background-size: 200px;
  }
  .landingTop h4 {
    font-size: 13px !important;
    margin-bottom: 5px;
  }
  .logoImage {
    width: 60px;
    margin-right: 10px;
  }
  .landingTop {
    width: 50%;
  }
  .landing sub {
    font-size: 9px;
  }
}
