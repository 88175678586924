.chatbot_body {
  background-color: #fff;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  overflow-y: scroll;
  height: 100%;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  /*background-image: url(https://surveyassistant.online//webtheme/img/backgroud.png);*/
}
.icon_twoLine {
  /*font-size: 35px !important;*/
  text-align: center;
  margin: 0 auto;
}
.QuickReplyMainParent {
  padding-left: 8px;
  padding-top: 5px;
  padding-right: 70px;
}

#chatbot_body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
#chatbot_body::-webkit-scrollbar-track {
  background: transparent;
}

#chatbot_body::-webkit-scrollbar-thumb {
  background: #1c4fc3;
  border-radius: 20px;
  padding: 10px;
}
