.footer-top-section,
.footer-mid-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-family: "Lato";
}
.footer-mid-section {
  width: 65%;
  margin: 20px auto;
  justify-content: space-evenly;
  border-width: 0px 0px 0px 0px;
  border-color: #ececed;
  border-style: solid;
}
.footer-top-section > div {
  width: 33%;
}
.footer-top-section > div:nth-child(2) {
  width: 30%;
}
.top-second,
.footer-bottom-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer-bottom-section {
  border-top: 1px solid #eee;
  padding-top: 40px;
  width: 90%;
  margin: 20px auto;
  line-height: 1.5;
  color: #919191;
}

.location-title {
  font-size: 16px;
  line-height: 1.2em !important;
  text-align: center;
  text-transform: uppercase;
  color: #3f3f3f;
}
.location-title {
  /* color: red; */
  font-size: 16px;
}
.location-address {
  font-size: 15px;
  line-height: 0.6em !important;
  text-align: center;
  color: #919191;
}
.location-address p {
  font-size: 15px;
}
.top-second-social-media {
  width: 30%;
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
}
.footer-mid-firstimage {
  width: 15%;
}
.footer-mid-secondimage {
  width: 10%;
  height: auto;
}
.footer-mid-thirdimage {
  width: 55%;
}
@media screen and (min-width: 300px) and (max-width: 680px) {
  .section > div {
    width: 100%;
  }

  .footer-mid-section {
    width: 100%;
  }
  .footer-mid-firstimage {
    width: 70%;
    margin: 10px auto;
  }
  .footer-mid-secondimage {
    width: 35%;
    margin: 10px auto;
  }
  .footer-mid-thirdimage {
    width: 85%;
    margin: 10px auto;
  }
  .footer-top-section,
  .footer-mid-section,
  .footer-bottom-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* line-height: 0.5em; */
    font-size: 10px;
  }
}
